export enum MainActionType {
    SET_MEETING_ID = "MEETING_ID",
    SET_MEETING_INFO = "SET_MEETING_INFO",
    SET_MEETING_SESSION = "SET_MEETING_SESSION",
    SET_PARTICIPANT = "SET_PARTICIPANT",
    SET_ROOMSTATE = "SET_ROOMSTATE",
    REMOVE_PARTICIPANT = "REMOVE_PARTICIPANT",
    UPDATE_PARTICIPANT_LIST = "UPDATE_PARTICIPANT_LIST",
    SET_MAX_TILEINSLIDER = "SET_MAX_TILEINSLIDER",
    ADD_NOTIFICATION = "ADD_NOTIFICATION",
    REMOVE_NOTIFICATION = "REMOVE_NOTIFICATION",
    // ADD_HOME_NOTIFICATION = "ADD_HOME_NOTIFICATION",
    // REMOVE_HOME_NOTIFICATION = "REMOVE_HOME_NOTIFICATION",

    ADD_GROUPCHAT = "ADD_GROUPCHAT",
    ADD_PRIVATECHAT = "ADD_PRIVATECHAT",
    CLEAR_CHAT = "CLEAR_CHAT",
    SET_PRIVATECHAT_PARTICIPANT = "SET_PRIVATECHAT_PARTICIPANT",
    DEVICE_LIST = "DEVICE_LIST",
    CURRENT_DEVICE = "CURRENT_DEVICE",
    ADD_REACTIONS = "ADD_REACTIONS",
    REMOVE_REACTIONS = "REMOVE_REACTIONS",
    SET_HOSTNAME = "SET_HOSTNAME",
    SET_UNREAD_PRIVATECHAT = "SET_UNREAD_PRIVATECHAT",
    SET_UNREAD_GROUPCHAT = "SET_UNREAD_GROUPCHAT",
    SET_MEETING_TIMER = "SET_MEETING_TIMER",
    ADD_MODAL = "ADD_MODAL",
    REMOVE_MODAL = "REMOVE_MODAL",
    ADD_TO_WAITING_LIST = "ADD_TO_WAITING_LIST",
    REMOVE_FROM_WAITING_LIST = "REMOVE_FROM_WAITING_LIST",
    CLEAR_WAITING_LIST = "CLEAR_WAITING_LIST",
    CLEAR_PARTICIPANT_LIST = "CLEAR_PARTICIPANT_LIST",
    SET_MEETING_INVITE = "SET_MEETING_INVITE",
    PAUSE_VIDEO = "PAUSE_VIDEO",
    // PARTICIPANT_VIDEO_STATE = 'PARTICIPANT_VIDEO_STATE',
    MEETING_TIMER_REF = 'MEETING_TIMER_REF',
    CLEAR_MEETING_STORE = 'CLEAR_MEETING_STORE',
    VIDEO_QUALITY = 'VIDEO_QUALITY',
    SET_MEETING_ENVIORNEMNT = 'SET_MEETING_ENVIORNEMNT',
    SET_LOGGEDIN_USER_INFO = 'SET_LOGGEDIN_USER_INFO',
    SET_PARTICIPANT_LIST_FLAGS = 'SET_PARTICIPANT_LIST_FLAGS',
    SET_MEETING_LIST = 'SET_MEETING_LIST',
    SET_MEETING_TYPE = 'SET_MEETING_TYPE',
    SET_SPEAKING_STATE = "SET_SPEAKING_STATE",
    SET_TAB = "SET_TAB",
    SET_SCHEDULED_MEETING_DETAILS = "SET_SCHEDULED_MEETING_DETAILS",
    SET_KEYCLOAK_TOKEN = "SET_KEYCLOAK_TOKEN",
    SELECT_SCREENSHARE = "SELECT_SCREENSHARE",
    UPDATE_WAITING_LIST = "UPDATE_WAITING_LIST",
    ADD_WHITEBOARD_DATA = "ADD_WHITEBOARD_DATA",
    CLEAR_WHITEBOARD_DATA = "CLEAR_WHITEBOARD_DATA",
    SET_WHITEBOARD_STATE = "SET_WHITEBOARD_STATE",
    ACTIVE_CHAT = "ACTIVE_CHAT",
    SET_HANDRAISE = 'SET_HANDRAISE',
    SET_SELECTED_DATE = "SET_SELECTED_DATE",
    KEYCLOAK_LOGGEDIN_STATE = "KEYCLOAK_LOGGEDIN_STATE",
    SET_HOSTCONTROL_ID = 'SET_HOSTCONTROL_ID',
    SET_FILESHARE_MODAL_STATE = "SET_FILESHARE_MODAL_STATE",
    SET_FILESHARE_STATE = "SET_FILESHARE_STATE",
    SET_SCREENSHARE_PAUSE_LISTENER = "SET_SCREENSHARE_PAUSE_LISTENER",
    SET_SCREENSHARE_PAUSE_PUBLISHER = "SET_SCREENSHARE_PAUSE_PUBLISHER",
    SWITCH_SHARELIST = "SWITCH_SHARELIST",
    SET_MEDIA_STREAM  = "SET_MEDIA_STREAM",
    SET_MEMBERS_COUNT = "SET_MEMBERS_COUNT",
    TOGGLE_LEFTBAR = 'TOGGLE_LEFTBAR',
    SOUND_AND_NOTIFICATION = 'SOUND_AND_NOTIFICATION',
    NOTIFICATION_DATA = 'NOTIFICATION_DATA',
    BRANDING_INFO = 'BRANDING_INFO',
    SET_THEME = 'SET_THEME',
    SET_THEME_PALETTE = 'SET_THEME_PALETTE',
    SET_CALL_POPUP = "SET_CALL_POPUP",
    ENABLE_MOUSE_HOVER = "ENABLE_MOUSE_HOVER",
    PERMISSION_SETTINGS = 'PERMISSION_SETTINGS',
    AUTHENCATION_DATA = 'AUTHENCATION_DATA',
    TENANT_DATA = 'TENANT_DATA',
    CLEAR_LOGIN_SESSION = 'CLEAR_LOGIN_SESSION',
    TOPBAR_SHRINK = 'TOPBAR_SHRINK',
    LOGOUT_MODAL = 'LOGOUT_MODAL',
    GOOGLE_CALANDER_DATA = 'GOOGLE_CALANDER_DATA',
    EVENT_REMINDER = 'EVENT_REMINDER',
    REMOVE_EVENT_REMINDER = 'REMOVE_EVENT_REMINDER',
    REMOVE_ALL_REMINDER = 'REMOVE_ALL_REMINDER',
    SET_PIN_MODAL = 'SET_PIN_MODAL',
    SET_PIN_DATA = 'SET_PIN_DATA',

    SET_DIAL_MODAL = 'SET_DIAL_MODAL',
}