const transposePayload = (value: any) => {
  if (Array.isArray(value)) {
    let paylodData = ""

    value.forEach((node) => {
      if (paylodData.length <= 0) {
        paylodData += node
      } else {
        paylodData += `,${node}`
      }
    })

    return paylodData
  } else {
    return value
  }
}

export const generateQueryPayload = (url: string, data?: any) => {
  let QueryUrl = url

  if (data && Object.keys(data)?.length > 0) {
    for (const [key, value] of Object.entries(data)) {
      if (!(typeof value === "undefined")) {
        if (QueryUrl.includes("?")) {
          QueryUrl += "&" + `${key}=${transposePayload(value)}`
        } else {
          QueryUrl += "?" + `${key}=${transposePayload(value)}`
        }
      }
    }
  }

  return QueryUrl
}
