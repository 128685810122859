import { SMSActionType } from "../action-types/sms.action.types"

export const setSMSUsers = (data: any) => {
  return {
    type: SMSActionType.SET_SMS_USERS,
    data,
  }
}

export const setSMSTarget = (data: any, remove?: boolean, clear?: boolean) => {
  return {
    type: SMSActionType.SET_SMS_TARGET,
    data,
    remove,
    clear
  }
}

export const setUserMessage = (
  data: any,
  id: string,
  messageBySelf?: boolean
) => {
  return {
    type: SMSActionType.SET_USER_MESSAGE,
    data,
    id,
    messageBySelf,
  }
}
export const setReadCountSMS = (data: string, count: number) => {
  return {
    type: SMSActionType.SET_READ_SMS,
    data,
    count,
  }
}
export const setActiveSMS = (data: string) => {
  return {
    type: SMSActionType.SET_ACTIVE_SMS,
    data,
  }
}
export const setPreviousMessage = (data: string) => {
  return {
    type: SMSActionType.SET_PREVIOUS_MESSAGE,
    data,
  }
}
export const clearSMSStore = () => {
  return {
    type: SMSActionType.CLEAR_SMS_STORE,
  }
}
