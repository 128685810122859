import configs from "../sessionConfig"
import { EnvironmentLevelKeys } from "../../config/environmentConfigs/configTypes"
const serverRoot = {
  US3: "us3-server-node0.hoolva.com",
  prod: "ncsapp.com",
  US1: "us1-dev.hoolva.com",
  NCS: "us3-test.ncsapp.com",
  US7: "us7-cluster.hoolva.com",
  US2: "us2-stage.ncsapp.com",
  US1_NCS: "us1-dev.ncsapp.com",
  US5_NCS: "us5-dev.ncsapp.com",
  US9: "us9-dev.ncsapp.com",
  US11: "us11-dev.ncsapp.com",
}

export const DocumentRoot = [
  {
    domain: "us7-cluster.hoolva.com",
    env: "test",
  },
  {
    domain: "us3-test.ncsapp.com",
    env: "dev",
  },
  {
    domain: "us11-dev.ncsapp.com",
    env: "dev11",
  },
  {
    domain: "us2-stage.ncsapp.com",
    env: "staging",
  },
  {
    domain: "ncsapp.com",
    env: "prod",
  },
]

class Environment {
  static instance: Environment
  rootUrl: string
  keycloak_rootUrl: string
  keycloak_reg: string
  keycloak_logout: string
  keycloak_tenant_login: string
  siloute_api: string
  tenant_info_api: string
  personal_status: string
  update_user: string
  keycloak_get_tenant: (value: string) => string
  keycloak_api_login: (value: string) => string
  checkincomingcallstatus: string;
  forgot_password: string
  EnvironmentLevel: EnvironmentLevelKeys
  apikey: string
  loglevel: number
  googleCalander: any //testing google calender
  googleCalanderDelete: any
  reminderModal: any

  private constructor(
    env: EnvironmentLevelKeys,
    apikey: string,
    loglevel: number
  ) {
    this.EnvironmentLevel = env
    this.apikey = apikey
    this.loglevel = loglevel
    if (env === "PRODUCTION") {
      this.rootUrl = `https://${serverRoot.prod}/app`
      this.keycloak_rootUrl = `https://${serverRoot.prod}/sso/?d=desk&q=`
      this.keycloak_reg = `https://${serverRoot.prod}/sso/?d=desk&q=signup`
      this.keycloak_logout = `https://${serverRoot.prod}/v2/srv/prov/user/logout/authentication`
      this.keycloak_tenant_login = `https://${serverRoot.prod}/v2/email/clients?email=`
      this.tenant_info_api = `https://${serverRoot.prod}/v2/srv/prov/tenantinfo`
      this.siloute_api = `https://${serverRoot.prod}/v2/srv/pbx/pbx_details`
      this.personal_status = `https://${serverRoot.prod}/v2/srv/chat/personal/status`
      this.update_user = `https://${serverRoot.prod}/v2/srv/prov/users`
      this.keycloak_get_tenant = (userName: string) =>
        `https://${serverRoot.prod}/v2/email/clients?email=${userName}`
      this.keycloak_api_login = (name: string) =>
        `https://${serverRoot.prod}/auth/realms/${name}/protocol/openid-connect/token`
      this.forgot_password = `https://${serverRoot.prod}/v2/reset_password`
      this.googleCalander = (
        tenantId: string,
        startTime: number,
        endTime: number
      ) =>
        `https://${serverRoot.prod}/v2/tenant/${tenantId}/calender/fetch?starttime=${startTime}&endtime=${endTime}`
      this.googleCalanderDelete = (tenant_id: string, meetingUuid: string) =>
        `https://${serverRoot.prod}/v2/tenant/${tenant_id}/calender?event_id=${meetingUuid}`
      this.reminderModal = (uuid: string) =>
        `https://${serverRoot.prod}/v2/srv/prov/meet_reminders`
      this.checkincomingcallstatus = `https://${serverRoot.prod}/v1/srv/pbx/check_call`;
    } else if (env === "NCSTEST") {
      this.rootUrl = `https://${serverRoot.NCS}/app`
      this.keycloak_rootUrl = `https://${serverRoot.NCS}/sso/?d=desk&q=`
      this.keycloak_reg = `https://${serverRoot.NCS}/sso/?d=desk&q=signup`
      this.keycloak_logout = `https://${serverRoot.NCS}/v2/srv/prov/user/logout/authentication`
      this.keycloak_tenant_login = `https://${serverRoot.NCS}/v2/email/clients?email=`
      this.tenant_info_api = `https://${serverRoot.NCS}/v2/srv/prov/tenantinfo`
      this.siloute_api = `https://${serverRoot.NCS}/v2/srv/pbx/pbx_details`
      this.personal_status = `https://${serverRoot.NCS}/v2/srv/chat/personal/status`
      this.update_user = `https://${serverRoot.NCS}/v2/srv/prov/users`
      this.keycloak_get_tenant = (userName: string) =>
        `https://${serverRoot.NCS}/v2/email/clients?email=${userName}`
      this.keycloak_api_login = (name: string) =>
        `https://${serverRoot.NCS}/auth/realms/${name}/protocol/openid-connect/token`
      this.forgot_password = `https://${serverRoot.NCS}/v2/reset_password`
      this.googleCalander = (
        tenantId: string,
        startTime: number,
        endTime: number
      ) =>
        `https://${serverRoot.NCS}/v2/tenant/${tenantId}/calender/fetch?starttime=${startTime}&endtime=${endTime}`
      this.googleCalanderDelete = (tenant_id: string, meetingUuid: string) =>
        `https://${serverRoot.NCS}/v2/tenant/${tenant_id}/calender?event_id=${meetingUuid}`
      this.reminderModal = (uuid: string) =>
        `https://${serverRoot.NCS}/v2/srv/prov/meet_reminders`
      this.checkincomingcallstatus = `https://${serverRoot.NCS}/v1/srv/pbx/check_call`;
    } else if (env === "DEVELOPMENT") {
      this.rootUrl = `https://${serverRoot.US1}/app`
      this.keycloak_rootUrl = `https://${serverRoot.US1}/sso/?d=desk&q=`
      this.keycloak_reg = `https://${serverRoot.US1}/sso/?d=desk&q=signup`
      this.keycloak_logout = `https://${serverRoot.US1}/v2/srv/prov/user/logout/authentication`
      this.keycloak_tenant_login = `https://${serverRoot.US1}/v2/email/clients?email=`
      this.tenant_info_api = `https://${serverRoot.US1}/v2/srv/prov/tenantinfo`
      this.siloute_api = `https://${serverRoot.US1}/v2/srv/pbx/pbx_details`
      this.personal_status = `https://${serverRoot.US1}/v2/srv/chat/personal/status`
      this.update_user = `https://${serverRoot.US1}/v2/srv/prov/users`
      this.keycloak_get_tenant = (userName: string) =>
        `https://${serverRoot.US1}/v2/email/clients?email=${userName}`
      this.keycloak_api_login = (name: string) =>
        `https://us3-test.hoolva.com/auth/realms/${name}/protocol/openid-connect/token`
      this.forgot_password = `https://${serverRoot.US1}/v2/reset_password`
      this.googleCalander = (
        tenantId: string,
        startTime: number,
        endTime: number
      ) =>
        `https://${serverRoot.US1}/v2/tenant/${tenantId}/calender/fetch?starttime=${startTime}&endtime=${endTime}`
      this.googleCalanderDelete = (tenant_id: string, meetingUuid: string) =>
        `https://${serverRoot.US1}/v2/tenant/${tenant_id}/calender?event_id=${meetingUuid}`
      this.reminderModal = (uuid: string) =>
        `https://${serverRoot.US1}/v2/srv/prov/meet_reminders`
      this.checkincomingcallstatus = `https://${serverRoot.US1}/v1/srv/pbx/check_call`;
    } else if (env === "STAGING-3") {
      this.rootUrl = `https://${serverRoot.US2}/app`
      this.keycloak_rootUrl = `https://${serverRoot.US2}/sso/?d=desk&q=`
      this.keycloak_reg = `https://${serverRoot.US2}/sso/?d=desk&q=signup`
      this.keycloak_logout = `https://${serverRoot.US2}/v2/srv/prov/user/logout/authentication`
      this.keycloak_tenant_login = `https://${serverRoot.US2}/v2/email/clients?email=`
      this.tenant_info_api = `https://${serverRoot.US2}/v2/srv/prov/tenantinfo`
      this.siloute_api = `https://${serverRoot.US2}/v2/srv/pbx/pbx_details`
      this.personal_status = `https://${serverRoot.US2}/v2/srv/chat/personal/status`
      this.update_user = `https://${serverRoot.US2}/v2/srv/prov/users`
      this.keycloak_get_tenant = (userName: string) =>
        `https://${serverRoot.US2}/v2/email/clients?email=${userName}`
      this.keycloak_api_login = (name: string) =>
        `https://${serverRoot.US2}/auth/realms/${name}/protocol/openid-connect/token`
      this.forgot_password = `https://${serverRoot.US2}/v2/reset_password`
      this.googleCalander = (
        tenantId: string,
        startTime: number,
        endTime: number
      ) =>
        `https://${serverRoot.US2}/v2/tenant/${tenantId}/calender/fetch?starttime=${startTime}&endtime=${endTime}`
      this.googleCalanderDelete = (tenant_id: string, meetingUuid: string) =>
        `https://${serverRoot.US2}/v2/tenant/${tenant_id}/calender?event_id=${meetingUuid}`
      this.reminderModal = (uuid: string) =>
        `https://${serverRoot.US2}/v2/srv/prov/meet_reminders`
      this.checkincomingcallstatus = `https://${serverRoot.US2}/v1/srv/pbx/check_call`;
    } else if (env === "SANDBOX") {
      this.rootUrl = `https://${serverRoot.US7}/app`
      this.keycloak_rootUrl = `https://${serverRoot.US7}/sso/?d=desk&q=`
      this.keycloak_reg = `https://${serverRoot.US7}/sso/?d=desk&q=signup`
      this.keycloak_logout = `https://${serverRoot.US7}/v2/srv/prov/user/logout/authentication`
      this.keycloak_tenant_login = `https://${serverRoot.US7}/v2/email/clients?email=`
      this.tenant_info_api = `https://${serverRoot.US7}/v2/srv/prov/tenantinfo`
      this.siloute_api = `https://${serverRoot.US7}/v2/srv/pbx/pbx_details`
      this.personal_status = `https://${serverRoot.US7}/v2/srv/chat/personal/status`
      this.update_user = `https://${serverRoot.US7}/v2/srv/prov/users`
      this.keycloak_get_tenant = (userName: string) =>
        `https://${serverRoot.US7}/v2/email/clients?email=${userName}`
      this.keycloak_api_login = (name: string) =>
        `https://us1dev-ncs.kanimango.com/auth/realms/${name}/protocol/openid-connect/token`
      this.forgot_password = `https://${serverRoot.US7}/v2/reset_password`
      this.googleCalander = (
        tenantId: string,
        startTime: number,
        endTime: number
      ) =>
        `https://${serverRoot.US7}/v2/tenant/${tenantId}/calender/fetch?starttime=${startTime}&endtime=${endTime}`
      this.googleCalanderDelete = (tenant_id: string, meetingUuid: string) =>
        `https://${serverRoot.US7}/v2/tenant/${tenant_id}/calender?event_id=${meetingUuid}`
      this.reminderModal = (uuid: string) =>
        `https://${serverRoot.US7}/v2/srv/prov/meet_reminders`
      this.checkincomingcallstatus = `https://${serverRoot.US7}/v1/srv/pbx/check_call`;
    } else if (env === "KANIMANGOTHREE") {
      this.rootUrl = `https://${serverRoot.US1}/app`
      this.keycloak_rootUrl = `https://${serverRoot.US1}/sso/?d=desk&q=`
      this.keycloak_reg = `https://${serverRoot.US1}/sso/?d=desk&q=signup`
      this.keycloak_logout = `https://${serverRoot.US1}/v2/srv/prov/user/logout/authentication`
      this.keycloak_tenant_login = `https://${serverRoot.US1}/v2/email/clients?email=`
      this.tenant_info_api = `https://${serverRoot.US1}/v2/srv/prov/tenantinfo`
      this.siloute_api = `https://${serverRoot.US1}/v2/srv/pbx/pbx_details`
      this.personal_status = `https://${serverRoot.US1}/v2/srv/chat/personal/status`
      this.update_user = `https://${serverRoot.US1}/v2/srv/prov/users`
      this.keycloak_get_tenant = (userName: string) =>
        `https://${serverRoot.US1}/v2/email/clients?email=${userName}`
      this.keycloak_api_login = (name: string) =>
        `https://us3-test.hoolva.com/auth/realms/${name}/protocol/openid-connect/token`
      this.forgot_password = `https://${serverRoot.US1}/v2/reset_password`
      this.googleCalander = (
        tenantId: string,
        startTime: number,
        endTime: number
      ) =>
        `https://${serverRoot.US1}/v2/tenant/${tenantId}/calender/fetch?starttime=${startTime}&endtime=${endTime}`
      this.googleCalanderDelete = (tenant_id: string, meetingUuid: string) =>
        `https://${serverRoot.US1}/v2/tenant/${tenant_id}/calender?event_id=${meetingUuid}`
      this.reminderModal = (uuid: string) =>
        `https://${serverRoot.US1}/v2/srv/prov/meet_reminders`
      this.checkincomingcallstatus = `https://${serverRoot.US1}/v1/srv/pbx/check_call`;
    } else if (env === "ALPHADEVELOP") {
      this.rootUrl = `https://${serverRoot.US1_NCS}/app`
      this.keycloak_rootUrl = `https://${serverRoot.US1_NCS}/sso/?d=desk&q=`
      this.keycloak_reg = `https://${serverRoot.US1_NCS}/sso/?d=desk&q=signup`
      this.keycloak_logout = `https://${serverRoot.US1_NCS}/v2/srv/prov/user/logout/authentication`
      this.keycloak_tenant_login = `https://${serverRoot.US1_NCS}/v2/email/clients?email=`
      this.tenant_info_api = `https://${serverRoot.US1_NCS}/v2/srv/prov/tenantinfo`
      this.siloute_api = `https://${serverRoot.US1_NCS}/v2/srv/pbx/pbx_details`
      this.personal_status = `https://${serverRoot.US1_NCS}/v2/srv/chat/personal/status`
      this.update_user = `https://${serverRoot.US1_NCS}/v2/srv/prov/users`
      this.keycloak_get_tenant = (userName: string) =>
        `https://${serverRoot.US1_NCS}/v2/email/clients?email=${userName}`
      this.keycloak_api_login = (name: string) =>
        `https://${serverRoot.US1_NCS}/auth/realms/${name}/protocol/openid-connect/token`
      this.forgot_password = `https://${serverRoot.US1_NCS}/v2/reset_password`
      this.googleCalander = (
        tenantId: string,
        startTime: number,
        endTime: number
      ) =>
        `https://${serverRoot.US1_NCS}/v2/tenant/${tenantId}/calender/fetch?starttime=${startTime}&endtime=${endTime}`
      this.googleCalanderDelete = (tenant_id: string, meetingUuid: string) =>
        `https://${serverRoot.US1_NCS}/v2/tenant/${tenant_id}/calender?event_id=${meetingUuid}`
      this.reminderModal = (uuid: string) =>
        `https://${serverRoot.US1_NCS}/v2/srv/prov/meet_reminders`
      this.checkincomingcallstatus = `https://${serverRoot.US1_NCS}/v1/srv/pbx/check_call`;
    } else if (env === "ALPHASANDBOX") {
      this.rootUrl = `https://${serverRoot.US5_NCS}/app`
      this.keycloak_rootUrl = `https://${serverRoot.US5_NCS}/sso/?d=desk&q=`
      this.keycloak_reg = `https://${serverRoot.US5_NCS}/sso/?d=desk&q=signup`
      this.keycloak_logout = `https://${serverRoot.US5_NCS}/v2/srv/prov/user/logout/authentication`
      this.keycloak_tenant_login = `https://${serverRoot.US5_NCS}/v2/email/clients?email=`
      this.tenant_info_api = `https://${serverRoot.US5_NCS}/v2/srv/prov/tenantinfo`
      this.siloute_api = `https://${serverRoot.US5_NCS}/v2/srv/pbx/pbx_details`
      this.personal_status = `https://${serverRoot.US5_NCS}/v2/srv/chat/personal/status`
      this.update_user = `https://${serverRoot.US5_NCS}/v2/srv/prov/users`
      this.keycloak_get_tenant = (userName: string) =>
        `https://${serverRoot.US5_NCS}/v2/email/clients?email=${userName}`
      this.keycloak_api_login = (name: string) =>
        `https://${serverRoot.US5_NCS}/auth/realms/${name}/protocol/openid-connect/token`
      this.forgot_password = `https://${serverRoot.US5_NCS}/v2/reset_password`
      this.googleCalander = (
        tenantId: string,
        startTime: number,
        endTime: number
      ) =>
        `https://${serverRoot.US5_NCS}/v2/tenant/${tenantId}/calender/fetch?starttime=${startTime}&endtime=${endTime}`
      this.googleCalanderDelete = (tenant_id: string, meetingUuid: string) =>
        `https://${serverRoot.US5_NCS}/v2/tenant/${tenant_id}/calender?event_id=${meetingUuid}`
      this.reminderModal = (uuid: string) =>
        `https://${serverRoot.US5_NCS}/v2/srv/prov/meet_reminders`
      this.checkincomingcallstatus = `https://${serverRoot.US5_NCS}/v1/srv/pbx/check_call`;
    } else if (env === "GAMMADEVELOP") {
      this.rootUrl = `https://${serverRoot.US9}/app`
      this.keycloak_rootUrl = `https://${serverRoot.US9}/sso/?d=desk&q=`
      this.keycloak_reg = `https://${serverRoot.US9}/sso/?d=desk&q=signup`
      this.keycloak_logout = `https://${serverRoot.US9}/v2/srv/prov/user/logout/authentication`
      this.keycloak_tenant_login = `https://${serverRoot.US9}/v2/email/clients?email=`
      this.tenant_info_api = `https://${serverRoot.US9}/v2/srv/prov/tenantinfo`
      this.siloute_api = `https://${serverRoot.US9}/v2/srv/pbx/pbx_details`
      this.personal_status = `https://${serverRoot.US9}/v2/srv/chat/personal/status`
      this.update_user = `https://${serverRoot.US9}/v2/srv/prov/users`
      this.keycloak_get_tenant = (userName: string) =>
        `https://${serverRoot.US9}/v2/email/clients?email=${userName}`
      this.keycloak_api_login = (name: string) =>
        `https://${serverRoot.US9}/auth/realms/${name}/protocol/openid-connect/token`
      this.forgot_password = `https://${serverRoot.US9}/v2/reset_password`
      this.googleCalander = (
        tenantId: string,
        startTime: number,
        endTime: number
      ) =>
        `https://${serverRoot.US9}/v2/tenant/${tenantId}/calender/fetch?starttime=${startTime}&endtime=${endTime}`
      this.googleCalanderDelete = (tenant_id: string, meetingUuid: string) =>
        `https://${serverRoot.US9}/v2/tenant/${tenant_id}/calender?event_id=${meetingUuid}`
      this.reminderModal = (uuid: string) =>
        `https://${serverRoot.US9}/v2/srv/prov/meet_reminders`
      this.checkincomingcallstatus = `https://${serverRoot.US9}/v1/srv/pbx/check_call`;
    } else if (env === "GAMMASANBOX") {
      this.rootUrl = `https://${serverRoot.US11}/app`
      this.keycloak_rootUrl = `https://${serverRoot.US11}/sso/?d=desk&q=`
      this.keycloak_reg = `https://${serverRoot.US11}/sso/?d=desk&q=signup`
      this.keycloak_logout = `https://${serverRoot.US11}/v2/srv/prov/user/logout/authentication`
      this.keycloak_tenant_login = `https://${serverRoot.US11}/v2/email/clients?email=`
      this.tenant_info_api = `https://${serverRoot.US11}/v2/srv/prov/tenantinfo`
      this.siloute_api = `https://${serverRoot.US11}/v2/srv/pbx/pbx_details`
      this.personal_status = `https://${serverRoot.US11}/v2/srv/chat/personal/status`
      this.update_user = `https://${serverRoot.US11}/v2/srv/prov/users`
      this.keycloak_get_tenant = (userName: string) =>
        `https://${serverRoot.US11}/v2/email/clients?email=${userName}`
      this.keycloak_api_login = (name: string) =>
        `https://${serverRoot.US11}/auth/realms/${name}/protocol/openid-connect/token`
      this.forgot_password = `https://${serverRoot.US11}/v2/reset_password`
      this.googleCalander = (
        tenantId: string,
        startTime: number,
        endTime: number
      ) =>
        `https://${serverRoot.US11}/v2/tenant/${tenantId}/calender/fetch?starttime=${startTime}&endtime=${endTime}`
      this.googleCalanderDelete = (tenant_id: string, meetingUuid: string) =>
        `https://${serverRoot.US11}/v2/tenant/${tenant_id}/calender?event_id=${meetingUuid}`
      this.reminderModal = (uuid: string) =>
        `https://${serverRoot.US11}/v2/srv/prov/meet_reminders`
      this.checkincomingcallstatus = `https://${serverRoot.US11}/v1/srv/pbx/check_call`;
    } else {
      this.rootUrl = `https://${serverRoot.US3}/app`
      this.keycloak_rootUrl = `https://${serverRoot.US3}/sso/?d=desk&q=`
      this.keycloak_reg = `https://${serverRoot.US3}/sso/?d=desk&q=signup`
      this.keycloak_logout = `https://${serverRoot.US3}/v2/srv/prov/user/logout/authentication`
      this.keycloak_tenant_login = `https://${serverRoot.US3}/v2/email/clients?email=`
      this.tenant_info_api = `https://${serverRoot.US3}/v2/srv/prov/tenantinfo`
      this.siloute_api = `https://${serverRoot.US3}/v2/srv/pbx/pbx_details`
      this.personal_status = `https://${serverRoot.US3}/v2/srv/chat/personal/status`
      this.update_user = `https://${serverRoot.US3}/v2/srv/prov/users`
      this.keycloak_get_tenant = (userName: string) =>
        `https://${serverRoot.US3}/v2/email/clients?email=${userName}`
      this.keycloak_api_login = (name: string) =>
        `https://${serverRoot.US3}/auth/realms/${name}/protocol/openid-connect/token`
      this.forgot_password = `https://${serverRoot.US3}/v2/reset_password`
      this.googleCalander = (
        tenantId: string,
        startTime: number,
        endTime: number
      ) =>
        `https://${serverRoot.US3}/v2/tenant/${tenantId}/calender/fetch?starttime=${startTime}&endtime=${endTime}`
      this.googleCalanderDelete = (tenant_id: string, meetingUuid: string) =>
        `https://${serverRoot.US3}/v2/tenant/${tenant_id}/calender?event_id=${meetingUuid}`
      this.reminderModal = (uuid: string) =>
        `https://${serverRoot.US3}/v2/srv/prov/meet_reminders`
      this.checkincomingcallstatus = `https://${serverRoot.US3}/v1/srv/pbx/check_call`;
    }
  }

  public static getInstance(): Environment {
    if (!Environment.instance) {
      Environment.instance = new Environment(
        configs.environmentLevel,
        configs.apiKey,
        configs.logLevel
      )
    }
    return Environment.instance
  }
}

export { Environment, serverRoot }
