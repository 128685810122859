import { SMSAction } from "../actions"
import { SMSActionType } from "../action-types/sms.action.types"
import { transposeArrayToObject } from "../../utils/TransposeArraytoObject"

interface SmsRepoState {
  targets: Object[]
  smsUsers: any
  activeSMS: string | null
}

const initialState = {
  targets: [],
  smsUsers: [],
  activeSMS: null,
}

const checkForDuplicate = (array: any, uuid: any) => {
  const message_index = array.messages.findIndex(
    (message: any) => message.uuid === uuid
  )
  return message_index
}

const SmsReducer = (
  state: SmsRepoState = initialState,
  action: SMSAction
): SmsRepoState => {
  switch (action.type) {
    case SMSActionType.SET_SMS_USERS: {
      return {
        ...state,
        smsUsers: action.data,
      }
    }
    case SMSActionType.SET_USER_MESSAGE: {
      const USERLIST = [...state.smsUsers]

      const USER_INDEX = USERLIST.findIndex((data: any) => {
        return data.number === action.id || data.user == action.id
      })
      if(USER_INDEX !== -1 || Array.isArray(action?.data)){
      USERLIST[USER_INDEX].messages = !Array.isArray(action?.data)
        ? [...(USERLIST[USER_INDEX]?.messages ?? []), action.data]
        : action?.data

      if (!Array.isArray(action?.data)) {
        //Message here is the lastMessage to display in userNode
        USERLIST[USER_INDEX].last_message = action.data.message
        USERLIST[USER_INDEX].time = action.data.time ?? action.data.a_ctime
        if (state.activeSMS !== action.id && !action.messageBySelf) {
          USERLIST[USER_INDEX].unread_count += 1
        }
      }

      if (!Array.isArray(action?.data)) {
        const USER = USERLIST[USER_INDEX]
        USERLIST.splice(USER_INDEX, 1)
        USERLIST.unshift(USER)
      }
    } else {
      const USER = {
        user : action.messageBySelf ? action.data.to_user : action.data.from_user ,
        time: action.data.a_ctime,
        number : action.messageBySelf ? action.data.to_number : action.data.from_number,
        name : action.messageBySelf ? (action.data.to_user_name !== 'undefined' ? action.data.to_user_name : action.data.to_number ) : (action.data.from_user_name !== 'undefined' ? action.data.from_user_name : action.data.from_number),
        last_message: action.data.message,
        unread_count: action.messageBySelf ? 0 :  1,
      }

      USERLIST.unshift(USER)
    }

      return {
        ...state,
        smsUsers: USERLIST,
      }
    }
    case SMSActionType.SET_SMS_TARGET: {
      let TARGET = [...state.targets]
      if (action.remove) {
        TARGET = TARGET.filter((target: any) => {
          if (target.user !== "undefined") {
            return target.user !== action.data.user
          } else if (target.number !== "undefined") {
            return target.number !== action.data.number
          }
        })
      } else if(action.clear){
        TARGET = []
      } else {
        TARGET = [...state.targets, action.data]
      }
      return {
        ...state,
        targets: TARGET,
      }
    }
    case SMSActionType.SET_READ_SMS: {
      const USERLIST = [...state.smsUsers]
      const USER_INDEX = USERLIST.findIndex((data: any) => {
        return data.number === action.data || data.user == action.data
      })
      USERLIST[USER_INDEX].unread_count = action.count
      return {
        ...state,
        smsUsers: USERLIST,
      }
    }
    case SMSActionType.SET_ACTIVE_SMS: {
      return {
        ...state,
        activeSMS: action.data,
      }
    }
    case SMSActionType.SET_PREVIOUS_MESSAGE: {
      const USERLIST = [...state.smsUsers]

      const USER_INDEX = USERLIST.findIndex((data: any) => {
        return data.number === state.activeSMS || data.user == state.activeSMS
      })
      USERLIST[USER_INDEX].messages = [
        ...action.data,
        ...USERLIST[USER_INDEX].messages,
      ]

      return {
        ...state,
        smsUsers: USERLIST,
      }
    }
    case SMSActionType.CLEAR_SMS_STORE: {
      return {
        ...initialState,
      }
    }
    default:
      return state
  }
}
export default SmsReducer
