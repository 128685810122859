import React from "react"
import ReactDOM from "react-dom"
import "./index.css"
import "./i18n/i18n"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import { ErrorBoundary } from "react-error-boundary"
import ErrorBoundaryPage from "./layout/layout1/components/errorBoundaryPage"
import CoreInstance from "./config/environmentConfigs/coreInstance"
import { v4 as uuidv4 } from "uuid"
import * as Sentry from "@sentry/react";
import { useLocation , useNavigationType , createRoutesFromChildren , matchRoutes } from 'react-router-dom';

const ID = uuidv4()
const logError = (error: Error, info: { componentStack: string }) => {
  console.log(error, "Crash Report", info.componentStack)
  // Do something with the error, e.g. log to an external API
  const chatInstance = CoreInstance.getChatInstance()
  chatInstance.grafanaLogger([
    `Client Crashed : Crash ID = ${ID}, Error: ${error}, Error Cause: ${error.cause}, Error Message: ${error.message}, Error name: ${error.name}, Stack: ${error.stack}, Component Stack:${info.componentStack}`,
  ])
}

Sentry.init({
  dsn: "https://36c1cdd51cfea9de856b33ffeca23bdf@sentry.netstratum.com/5",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
    Sentry.replayCanvasIntegration()
  ],
  debug : true,
  tracesSampleRate: 1.0,
  release: 'ncs-web',
  tracePropagationTargets: ["localhost"],
  replaysSessionSampleRate: 0, 
  replaysOnErrorSampleRate: 0,
});
const replay = Sentry.getReplay();
replay?.start();


ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary fallback={<ErrorBoundaryPage ID={ID} />} onError={logError}>
      <App />
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById("root")
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
